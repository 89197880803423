import { placeService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const SET_SYSTEM_LIST = 'SET_SYSTEM_LIST'
const SET_PMS_LIST = 'SET_PMS_LIST'
const SET_USER_LIST = 'SET_USER_LIST'
const DEL_USER_ITEM = 'DEL_USER_ITEM'
const ADD_USER_ITEM = 'ADD_USER_ITEM'

const state = {
    defaultItem: {
        _type: 'Place',
        id: -1,
        text: '',
        hash: '',
    },
    systemList: [],
    pmsList: [],
    userList: [],
    currentItem: {},
}

const getters = {
    systemPlaces: (state) => state.systemList,
    pmsPlaces: (state) => state.pmsList,
    userPlaces: (state) => state.userList,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    [SET_SYSTEM_LIST](state, items) {
        state.systemList = items
    },
    [SET_PMS_LIST](state, items) {
        state.pmsList = items
    },
    [SET_USER_LIST](state, items) {
        state.userList = items
    },
    [ADD_USER_ITEM](state) {
        state.userList.push(state.currentItem)
    },
    [DEL_USER_ITEM](state, id) {
        state.userList.splice(
            state.userList.findIndex((item) => item.id == id),
            1
        )
    },
    updateField,
}

const actions = {
    async getSystemItems({ commit }, id) {
        await placeService.getSystemItems(id).then((items) => {
            commit(SET_SYSTEM_LIST, items)
        })
    },

    async getPmsItems({ commit }) {
        await placeService.getPmsItems().then((items) => {
            commit(SET_PMS_LIST, items)
        })
    },

    async getUserItems({ commit }) {
        await placeService.getUserItems().then((items) => {
            commit(SET_USER_LIST, items)
        })
    },

    async addUserItem(commit, text) {
        let id = await placeService.newUserItem(text)
        return id
    },

    async deleteUserItem({ commit }, id) {
        await placeService.delUserItem(id).then(() => {
            commit(DEL_USER_ITEM, id)
        })
    },
}

export const place = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
