import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context('.', true, /[A-Z]\w+\.(vue|js)$/)

// Globally register all components from
requireComponent.keys().forEach((fileName) => {
    const component = requireComponent(fileName)
    const componentName = upperFirst(camelCase(fileName.replace(/\.\w+$/, '')))
    Vue.component(componentName, component.default || component)
})

//  EXAMPLE component names:
// Global: <ErrorBoundary />
// Subfolder: <JobAdPubConnectorJobCloud />
