<template>
    <v-row>
        <v-card-text>
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('oampublication/label--jobcloud-offer-text')"
                rules="required"
            >
                <v-select
                    v-model="jobCloudValue"
                    :items="cboValues"
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--jobcloud-offer-text')"
                    :error-messages="errors"
                    required
                    clearable
                ></v-select>
            </ValidationProvider>
        </v-card-text>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -10,
        loading: true,
        cboList: 'JobsCHoffers',
    }),
    computed: {
        ...mapGetters('cbo', ['cboValues']),
        ...mapGetters('pub', ['getExtensionValueByKey']),
        jobCloudValue: {
            get: function () {
                return this.getExtensionValueByKey('JobsCHofferID')
            },
            set: function (val) {
                this.editExtension({ key: 'JobsCHofferID', value: val })
            },
        },
    },
    mounted() {
        this.getItems(this.cboList).then(() => {
            this.getCompanySettings().then((companySettings) => {
                if (!this.jobCloudValue) {
                    if ('defaultJobsCHofferID' in companySettings) {
                        this.jobCloudValue = companySettings.defaultJobsCHofferID.toString()
                    }
                }
                setTimeout(() => {
                    this.loading = false
                    this.$emit('child-rendered')
                }, 500)
            })
        })
    },
    methods: {
        ...mapActions('cbo', ['getItems']),
        ...mapActions('pub', ['editExtension']),
        ...mapActions('settings', ['getCompanySettings']),
    },
}
</script>

<style scoped></style>
