import Vue from 'vue'
import boolean from './boolean'
import truncate from './truncate'
import uppercase from './uppercase'
import dateDisplay from './dateDisplay'

Vue.filter('boolean', boolean)
Vue.filter('truncate', truncate)
Vue.filter('uppercase', uppercase)
Vue.filter('datePresenter', dateDisplay)
