import Vue from 'vue'

import { loggerService } from '@/services'

Vue.config.errorHandler = (err, vm, info) => {
    loggerService.logToServer({ err, vm, info })
}

window.onerror = function (message, source, lineno, colno, error) {
    loggerService.logToServer({ message, source, lineno, colno, error })
}
