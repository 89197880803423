import { extensionService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const SET_LIST = 'SET_LIST'

const state = {
    defaultItem: {
        _type: 'PublicationExtension',
        id: -1,
        publicationId: -1,
        key: '',
        value: '',
    },
    list: [],
    currentItem: {},
}

const getters = {
    offers: (state) => state.list,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    [SET_LIST](state, items) {
        state.list = items
    },
    updateField,
}

const actions = {
    async getItems({ commit }, id) {
        await extensionService.getItems(id).then((items) => {
            commit(SET_LIST, items)
        })
    },
}

export const extension = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
