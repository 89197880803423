import Vue from 'vue'

const FILE = process.env.VUE_APP_API_URL_FILE
const COMPANY_ASSET = process.env.VUE_APP_API_URL_COMPANY_ASSET

export const fileService = {
    async getFile(id) {
        try {
            return await Vue.axios.get(FILE + id, { responseType: 'blob' }).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async newFile(id, file, progress) {
        try {
            let formData = new FormData()
            formData.append('file', file)

            return await Vue.axios
                .post(FILE + id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    progress,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delFile(id) {
        try {
            return await Vue.axios.delete(FILE + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async addCompanyAsset(guid, type) {
        try {
            return await Vue.axios
                .post(COMPANY_ASSET.slice(0, -1), {
                    companyAssetFileGuid: guid,
                    companyAssetTypeId: type,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delCompanyAsset(guid) {
        try {
            return await Vue.axios.delete(COMPANY_ASSET + guid).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
