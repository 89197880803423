<template>
    <v-row>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <validation-provider
                        v-slot="{ errors }"
                        :name="$t('oampublication/label--seco-avam-occupation')"
                        rules="required"
                    >
                        <v-autocomplete
                            v-model="secoAvamOccupationCode"
                            :items="cboSecoAvamOccupation"
                            item-text="text"
                            item-value="id"
                            prepend-inner-icon="mdi-magnify"
                            :label="$t('oampublication/label--seco-avam-occupation')"
                            :error-messages="errors"
                            auto-select-first
                            open-on-clear
                            clearable
                            required
                        >
                            <template #item="data">
                                <span :class="getItemColor(data.item)">{{ data.item.id }} - {{ data.item.text }}</span>
                            </template>
                            <template #selection="data">
                                <span :class="getItemColor(data.item)">{{ data.item.text }}</span>
                            </template>
                        </v-autocomplete>
                    </validation-provider>
                    <div class="text-right">
                        <v-chip color="warning" label small>{{
                            $t('oampublication/label--seco-job-reportable')
                        }}</v-chip>
                    </div>
                </v-col>
                <v-col cols="6"
                    ><v-select
                        v-model="secoNumberOfJobs"
                        :items="cboSecoNumberOfJobs"
                        :label="$t('oampublication/label--seco-number-of-jobs')"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-checkbox
                        v-model="secoPublicContact"
                        :label="$t('oampublication/label--seco-public-contact')"
                    ></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox
                        v-model="secoPublicDisplay"
                        :label="$t('oampublication/label--seco-public-display')"
                    ></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox
                        v-model="secoEuresDisplay"
                        :label="$t('oampublication/label--seco-eures-display')"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-card-text>
    </v-row>
</template>

<script>
import { cboService } from '@/services'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -30,
        loading: true,
        cboSecoAvamOccupation: [],
        cboSecoNumberOfJobs: [...Array(25).keys()].map((x) => (++x).toString()),
    }),
    computed: {
        ...mapGetters('pub', ['getExtensionValueByKey']),
        secoNumberOfJobs: {
            get: function () {
                return this.getExtensionValueByKey('SecoNumberOfJobs')
            },
            set: function (val) {
                this.editExtension({ key: 'SecoNumberOfJobs', value: val })
            },
        },
        secoAvamOccupationCode: {
            get: function () {
                return this.getExtensionValueByKey('SecoAvamOccupationCode')
            },
            set: function (val) {
                this.editExtension({ key: 'SecoAvamOccupationCode', value: val })
            },
        },
        secoPublicContact: {
            get: function () {
                return this.getExtensionValueByKey('SecoPublicContact')
            },
            set: function (val) {
                this.editExtension({ key: 'SecoPublicContact', value: val })
            },
        },
        secoEuresDisplay: {
            get: function () {
                return this.getExtensionValueByKey('SecoEuresDisplay')
            },
            set: function (val) {
                this.editExtension({ key: 'SecoEuresDisplay', value: val })
            },
        },
        secoPublicDisplay: {
            get: function () {
                return this.getExtensionValueByKey('SecoPublicDisplay')
            },
            set: function (val) {
                this.editExtension({ key: 'SecoPublicDisplay', value: val })
            },
        },
    },
    mounted() {
        // default initialization
        if (this.secoNumberOfJobs === '') this.secoNumberOfJobs = '1'
        if (this.secoPublicContact === '') this.secoPublicContact = true
        if (this.secoEuresDisplay === '') this.secoEuresDisplay = false
        if (this.secoPublicDisplay === '') this.secoPublicDisplay = true

        cboService.getItems('SecoAvamOccupation').then((items) => {
            this.cboSecoAvamOccupation = items
            this.$emit('child-rendered')
            this.loading = false
        })
    },
    methods: {
        ...mapActions('pub', ['editExtension']),
        getItemColor(item) {
            return item.reportable ? 'warning--text' : ''
        },
    },
}
</script>

<style scoped></style>
