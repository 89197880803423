import Vue from 'vue'
import App from '@/App.vue'
import { store } from '@/store'
import { router } from '@/router'
import { vuetify } from '@/plugins/'
import { makeServer } from '@/server'

import '@/components'
import '@/filters'

if (process.env.VUE_APP_API_URL == '/') {
    makeServer()
}

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: (h) => h(App),
})
