<template>
    <v-app>
        <error-boundary>
            <v-main>
                <router-view />
            </v-main>
            <v-snackbars :objects.sync="alerts" top centered>
                <template #action="{ close }">
                    <v-btn text @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbars>
        </error-boundary>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { tlService } from '@/services'
import VSnackbars from 'v-snackbars'

export default {
    components: {
        'v-snackbars': VSnackbars,
    },
    data: () => ({}),
    computed: {
        ...mapGetters('behavior', ['behavior']),
        ...mapFields('alert', { alerts: 'list' }),
    },
    async mounted() {
        await tlService.preloadTranslations('oamjobad')
        await tlService.preloadTranslations('oampublication')
    },
}
</script>

<style scoped lang="scss">
::v-deep .v-application--wrap {
    min-height: fit-content;
}
</style>
