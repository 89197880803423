import Vue from 'vue'

/**
 * Converts a boolean to text
 *
 * 'abc' => 'ABC'
 */

function boolean(value) {
    return value ? Vue.i18n.translate('common/label--yes') : Vue.i18n.translate('common/label--no')
}

export default boolean
