<template>
    <v-row>
        <v-card-text>
            <validation-provider
                v-slot="{ errors }"
                :name="$t('oampublication/label--healthjobs-department-text')"
                rules="required"
            >
                <v-select
                    v-model="healthjobsDepartmentID"
                    :items="cboHealthjobsDepartment"
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--healthjobs-department-text')"
                    :error-messages="errors"
                    required
                    clearable
                ></v-select>
            </validation-provider>
        </v-card-text>
    </v-row>
</template>

<script>
import { cboService } from '@/services'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -22,
        loading: true,
        cboHealthjobsDepartment: [],
    }),
    computed: {
        ...mapGetters('pub', ['getExtensionValueByKey']),
        healthjobsDepartmentID: {
            get: function () {
                return this.getExtensionValueByKey('HealthjobsDepartmentID')
            },
            set: function (val) {
                this.editExtension({ key: 'HealthjobsDepartmentID', value: val })
            },
        },
    },
    mounted() {
        cboService.getItems('HealthjobsDepartment').then((items) => {
            this.cboHealthjobsDepartment = items
            this.loading = false
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
