import Vue from 'vue'
import { store } from '@/store'
import { tlService, storageService } from '@/services'
import vuexI18n from 'vuex-i18n'
import moment from 'moment'

const language = storageService.get('userLanguage') || store.getters['user/language']

Vue.use(vuexI18n.plugin, store, {
    messages: loadTranslations(),
    // onTranslationNotFound(locale, key) {
    //     return tlService.getSingleTranslation(locale, key)
    // },
})

Vue.i18n.fallback('de')
Vue.i18n.set(language)
moment.locale('de')

async function loadTranslations() {
    return await tlService.preloadTranslations('oamcommon')
}
