import Vue from 'vue'
import axios from 'axios'
import { store } from '@/store'

const OAUTH_TOKEN = process.env.VUE_APP_YOUSTY_URL_OAUTH_TOKEN
const GET_APPRENTICESHIPS = process.env.VUE_APP_YOUSTY_URL_GET_APPRENTICESHIPS
const PUBLISH_APPRENTICESHIP = process.env.VUE_APP_YOUSTY_URL_PUBLISH_APPRENTICESHIP
const UNPUBLISH_APPRENTICESHIP = process.env.VUE_APP_YOUSTY_URL_UNPUBLISH_APPRENTICESHIP
const UNINSTALL_INTEGRATION = process.env.VUE_APP_YOUSTY_URL_UNINSTALL_INSTALLATION

const YOUSTY = process.env.VUE_APP_API_URL_YOUSTY

const instance = axios.create({
    baseURL: process.env.VUE_APP_YOUSTY_URL,
    withCredentials: false,
})

instance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        let message = error.response.data.detail.message
        const type = error.response.data.detail.exception_type

        console.log(message)

        switch (type) {
            case 'already_published':
                message = 'Lehrstelle ist bereits bei Yousty aufgeschaltet'
                break
            case 'yousty_api_error':
                message = 'Fehler auf dem Yousty Backend'
                break
            case 'invalid_token':
                message = 'Yousty Access Token ist ungültig'
                break
            case 'yousty_publication_exception':
                message = 'Fehler bei der Aufschaltung der Publikation auf Yousty'
                break
            case 'yousty_unpublication_exception':
                message = 'Fehler bei der Abschaltung der Publikation auf Yousty'
                break
            case 'yousty_apprenticeship_not_found':
                message = 'Lehrstelle wurde bei Yousty nicht gefunden'
                break
            default:
                message = `Fehler (${type}) auf dem Yousty Backend`
        }

        store.dispatch('alert/showAlert', {
            color: 'error',
            message: message,
        })

        return Promise.reject(error)
    }
)

export const youstyService = {
    // call to API
    async getItem(id) {
        try {
            return await Vue.axios.get(YOUSTY + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async setItem(item) {
        try {
            return await Vue.axios.put(YOUSTY + item.id, item).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    // call to YOUSTY
    async getOauthToken(clientId, clientSecret) {
        return await instance
            .post(OAUTH_TOKEN, {
                client_id: clientId,
                client_secret: clientSecret,
            })
            .then((response) => response.data.token)
            .catch((error) => error.response.data.detail.message)
    },
    async getApprenticeships(clientId, clientSecret) {
        let oauthToken = await this.getOauthToken(clientId, clientSecret)

        return await instance
            .post(GET_APPRENTICESHIPS, {
                yousty_token: oauthToken,
            })
            .then((response) => response.data.apprenticeships)
            .catch((error) => error.response.data.detail.message)
    },
    async publishApprenticeship(clientId, clientSecret, apiKey, apprenticeshipUuid, jobId, year, trial) {
        return await instance
            .post(PUBLISH_APPRENTICESHIP, {
                apprenticeship_details: {
                    yousty_apprenticeship_uuid: apprenticeshipUuid,
                    yousty_client_id: clientId,
                    yousty_client_secret: clientSecret,
                    ostendis_job_id: jobId,
                    ostendis_api_key: apiKey,
                    year: year,
                    trial: trial,
                },
            })
            .then((response) => response.data)
            .catch((error) => error.response.data.detail.message)
    },
    async unpublishApprenticeship(clientId, clientSecret, apiKey, apprenticeshipUuid, jobId, year, trial) {
        return await instance
            .post(UNPUBLISH_APPRENTICESHIP, {
                apprenticeship_details: {
                    yousty_apprenticeship_uuid: apprenticeshipUuid,
                    yousty_client_id: clientId,
                    yousty_client_secret: clientSecret,
                    ostendis_job_id: jobId,
                    ostendis_api_key: apiKey,
                    year: year,
                    trial: trial,
                },
            })
            .then((response) => response.data)
            .catch((error) => error.response.data.detail.message)
    },
    async uninstallIntegration(clientId, clientSecret, apiKey) {
        return await instance
            .post(UNINSTALL_INTEGRATION, {
                yousty_client_id: clientId,
                yousty_client_secret: clientSecret,
                ostendis_api_key: apiKey,
            })
            .then((response) => response.data)
            .catch((error) => error.response.data.detail.message)
    },
}
