<template>
    <v-row>
        <v-card-text>
            <validation-provider
                v-slot="{ errors }"
                :name="$t('oampublication/label--sozialinfo-position-text')"
                rules="required"
            >
                <v-select
                    v-model="sozialinfoPositionID"
                    :items="cboSozialinfoPosition"
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--sozialinfo-position-text')"
                    :error-messages="errors"
                    clearable
                    required
                    @change="filterSozialinfoPubOffer"
                ></v-select>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                :name="$t('oampublication/label--sozialinfo-publication-offer-text')"
                rules="required"
            >
                <v-select
                    v-model="sozialinfoPublicationOfferID"
                    :items="cboSozialinfoPubOffer"
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--sozialinfo-publication-offer-text')"
                    :error-messages="errors"
                    clearable
                    required
                ></v-select>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                :name="$t('oampublication/label--sozialinfo-working-area-text')"
                rules="required"
            >
                <v-select
                    v-model="sozialinfoWorkingAreaID"
                    :items="cboSozialinfoWorkingArea"
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--sozialinfo-working-area-text')"
                    :error-messages="errors"
                    clearable
                    required
                ></v-select>
            </validation-provider>
        </v-card-text>
    </v-row>
</template>

<script>
import { cboService } from '@/services'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -20,
        loading: true,
        cboSozialinfoPubOffer: [],
        cboSozialinfoPosition: [],
        cboSozialinfoWorkingArea: [],
    }),
    computed: {
        ...mapGetters('pub', ['getExtensionValueByKey']),
        sozialinfoPublicationOfferID: {
            get: function () {
                return this.getExtensionValueByKey('SozialinfoPublicationOfferID')
            },
            set: function (val) {
                this.editExtension({ key: 'SozialinfoPublicationOfferID', value: val })
            },
        },
        sozialinfoPositionID: {
            get: function () {
                return this.getExtensionValueByKey('SozialinfoPositionID')
            },
            set: function (val) {
                this.editExtension({ key: 'SozialinfoPositionID', value: val })
            },
        },
        sozialinfoWorkingAreaID: {
            get: function () {
                return this.getExtensionValueByKey('SozialinfoWorkingAreaID')
            },
            set: function (val) {
                this.editExtension({ key: 'SozialinfoWorkingAreaID', value: val })
            },
        },
    },
    mounted() {
        cboService.getItems('SozialinfoPubOffer').then((items) => {
            this.cboSozialinfoPubOffer = items

            cboService.getItems('SozialinfoPosition').then((items) => {
                this.cboSozialinfoPosition = items

                cboService.getItems('SozialinfoWorkingArea').then((items) => {
                    this.cboSozialinfoWorkingArea = items
                    this.loading = false
                    this.$emit('child-rendered')
                })
            })
        })
    },
    methods: {
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
