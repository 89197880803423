import he from 'he'
import { getField, updateField } from 'vuex-map-fields'

const SET_ALERT = 'SET_ALERT'
const REMOVE_ALERT = 'REMOVE_ALERT'

const state = {
    defaultItem: {
        color: null,
        message: null,
        timeout: -1,
    },
    list: [],
}

const getters = {
    alerts: (state) => state.list,
    getField,
}

const mutations = {
    [SET_ALERT](state, alert) {
        state.list.push(alert)
    },
    [REMOVE_ALERT](state, index) {
        state.list.splice(index, 1)
    },
    updateField,
}

const actions = {
    showError({ dispatch }, message) {
        const alert = {
            message: message,
            color: 'error',
            timeout: -1,
        }
        dispatch('showAlert', alert)
    },

    showWarning({ dispatch }, message) {
        const alert = {
            message: message,
            color: 'warning',
            timeout: -1,
        }
        dispatch('showAlert', alert)
    },

    showSuccess({ dispatch }, message) {
        const alert = {
            message: message,
            color: 'success',
            timeout: 5000,
        }
        dispatch('showAlert', alert)
    },

    showInfo({ dispatch }, message) {
        const alert = {
            message: message,
            color: 'info',
            timeout: 5000,
        }
        dispatch('showAlert', alert)
    },

    showAlert({ commit }, alert) {
        alert.message = he.decode(alert.message)
        alert.timeout = alert.color == 'error' || alert.color == 'warning' ? -1 : 5000
        commit(SET_ALERT, alert)
    },

    hideAlert({ commit }, index) {
        commit(REMOVE_ALERT, index)
    },
}

export const alert = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
