import Vue from 'vue'

const COMPANY_SETTINGS = process.env.VUE_APP_API_URL_COMPANY_SETTINGS

export const settingsService = {
    async getCompanySettings() {
        try {
            return await Vue.axios.get(COMPANY_SETTINGS).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
