<template>
    <v-overlay :value="loader" opacity="0.3">
        <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
</template>
<script>
export default {
    props: {
        loader: Boolean,
    },
}
</script>
