import { render, staticRenderFns } from "./Healthjobs.vue?vue&type=template&id=60a8f601&scoped=true"
import script from "./Healthjobs.vue?vue&type=script&lang=js"
export * from "./Healthjobs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a8f601",
  null
  
)

export default component.exports