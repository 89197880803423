import { templateService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const SET_LIST = 'SET_LIST'
const DEL_ITEM = 'DEL_ITEM'

const state = {
    defaultItem: {
        _type: 'JobAdTemplate',
        id: -1,
        language: null,
        isYouSalutation: null,
        title: '',
        createdAt: null,
        htmlDocId: -1,
        isOstendisTemplate: true,
        previewLink: '',
    },
    list: [],
    currentItem: {},
}

const getters = {
    templates: (state) => state.list,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    [SET_LIST](state, items) {
        state.list = items
    },
    [DEL_ITEM](state, id) {
        state.list.splice(
            state.list.findIndex((item) => item.id == id),
            1
        )
    },
    updateField,
}

const actions = {
    async getItems({ commit }, id) {
        await templateService.getItems(id).then((items) => {
            commit(SET_LIST, items)
        })
    },

    async deleteItem({ commit }, id) {
        await templateService.delItem(id).then(() => {
            commit(DEL_ITEM, id)
        })
    },
}

export const template = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
