import Vue from 'vue'

const CBO = process.env.VUE_APP_API_URL_CONNECTOR_CBO
const CBO_JOBUP = process.env.VUE_APP_API_URL_CONNECTOR_CBO_JOBUP
const CBO_SECO = process.env.VUE_APP_API_URL_CONNECTOR_CBO_SECO

export const cboService = {
    async getItems(list) {
        try {
            if (list === 'JobUpSubCategory') {
                return await Vue.axios.post(CBO_JOBUP.slice(0, -1), {}).then((response) => response.data)
            } else if (list === 'SecoAvamOccupation') {
                return await Vue.axios.post(CBO_SECO.slice(0, -1), {}).then((response) => response.data)
            } else {
                return await Vue.axios
                    .post(CBO.slice(0, -1), {
                        list: list,
                        subSystemCode: 'ODM',
                        onlyActiveEntries: 1,
                    })
                    .then((response) => response.data)
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
