import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { en, de, es, it, fr } from 'vuetify/es5/locale'

Vue.use(Vuetify)

export const vuetify = new Vuetify({
    lang: {
        locales: { de, en, es, it, fr },
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#3B5998',
                secondary: '#656971',
                accent: '#FF8200',
                error: '#CD1D1D',
                info: '#3B5998',
                success: '#60B635',
                warning: '#E9A735',
            },
        },
        options: { customProperties: false }, //customProperties break IE11 colors
    },
    icons: {
        iconfont: 'mdi',
    },
})

// see https://anice.red/#3b5998
// see https://coolors.co/3b5998-ced3dc-fcf7f8-ff8200-13293d
