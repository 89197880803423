import { settingsService } from '@/services'

const state = {
    companySettings: {},
}

const getters = {
    companySettings: (state) => state.companySettings,
}

const mutations = {}

const actions = {
    async getCompanySettings() {
        return await settingsService.getCompanySettings()
    },
}

export const settings = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
