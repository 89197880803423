const SET_LOADING = 'SET_LOADING'

const state = {
    loading: false,
}

const getters = {
    behavior: (state) => state,
}

const mutations = {
    [SET_LOADING](state, value) {
        state.loading = value
    },
}

const actions = {
    setLoading({ commit }, value) {
        commit(SET_LOADING, value)
    },
}

export const behavior = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
