<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="mt-5">
        <ValidationObserver ref="observer" v-slot="{ invalid, validated }">
            <v-toolbar flat>
                <v-toolbar-title
                    ><colored-status
                        :value="linked"
                        :tooltip="
                            linked
                                ? $t('oampublication/label--yousty-linked')
                                : $t('oampublication/label--yousty-not-linked')
                        "
                    />{{ $t('oampublication/label--yousty-title') }}</v-toolbar-title
                >
            </v-toolbar>
            <v-card v-if="auth !== null" class="mt-2" outlined>
                <v-card-text>
                    <v-row class="mb-5"
                        ><v-col>{{ $t('oampublication/label--yousty-description-active') }} </v-col></v-row
                    >
                    <v-row>
                        <v-col cols="6"
                            ><ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('oampublication/label--yousty-apprenticeship')"
                                rules="required"
                                ><v-select
                                    v-model="apprenticeshipUUID"
                                    :items="apprenticeships"
                                    item-value="apprenticeship_id"
                                    :label="$t('oampublication/label--yousty-apprenticeship')"
                                    :hint="$t('oampublication/label--yousty-apprenticeship-hint')"
                                    :error-messages="errors"
                                    :disabled="linked"
                                    required
                                >
                                    <template #selection="data">
                                        {{ data.item.profession.de_CH }} -&nbsp;{{ data.item.location.zip }}&nbsp;{{
                                            data.item.location.city
                                        }},&nbsp;{{ data.item.location.address }}
                                    </template>
                                    <template #item="data">
                                        {{ data.item.profession.de_CH }} -&nbsp;{{ data.item.location.zip }}&nbsp;{{
                                            data.item.location.city
                                        }},&nbsp;{{ data.item.location.address }}
                                    </template>
                                </v-select></ValidationProvider
                            ></v-col
                        >
                        <v-col cols="2">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('oampublication/label--yousty-year')"
                                rules="required"
                            >
                                <v-select
                                    v-model="year"
                                    :items="years"
                                    :label="$t('oampublication/label--yousty-year')"
                                    :hint="$t('oampublication/label--yousty-year-hint')"
                                    :error-messages="errors"
                                    :disabled="linked"
                                    required
                                ></v-select>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="2"
                            ><ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('oampublication/label--yousty-trial')"
                                rules="required"
                                ><v-select
                                    v-model="trial"
                                    :items="trials"
                                    item-text="text"
                                    item-value="id"
                                    :label="$t('oampublication/label--yousty-trial')"
                                    :hint="$t('oampublication/label--yousty-trial-hint')"
                                    :error-messages="errors"
                                    :disabled="linked"
                                    required
                                ></v-select></ValidationProvider
                        ></v-col>
                        <v-col cols="2" class="text-right">
                            <v-btn
                                v-if="!linked"
                                :disabled="invalid || !validated"
                                color="primary"
                                class="mt-3"
                                @click="link()"
                                >{{ $t('oampublication/label--yousty-link') }}</v-btn
                            >
                            <v-btn
                                v-if="linked"
                                :disabled="invalid || !validated"
                                color="primary"
                                class="mt-3"
                                @click="unlink()"
                                >{{ $t('oampublication/label--yousty-unlink') }}</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card v-else class="mt-2" outlined>
                <v-card-text>
                    <v-row
                        ><v-col class="text-left"
                            ><v-img width="10%" class="mb-5 pa-5" src="@/assets/yousty.png" contain></v-img>
                            <span v-html="$t('oampublication/label--yousty-description-not-active')"></span> </v-col
                    ></v-row>
                </v-card-text>
            </v-card>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data: () => ({
        auth: null,
        apiKey: null,
        jobId: null,

        years: [2024, 2025, 2026],

        trials: [
            { id: false, text: 'Nein' },
            { id: true, text: 'Ja' },
        ],
    }),
    computed: {
        ...mapGetters('yousty', ['apprenticeships']),
        ...mapFields('yousty', [
            'currentItem.id',
            'currentItem.apprenticeshipUUID',
            'currentItem.year',
            'currentItem.trial',
            'currentItem.linked',
        ]),
    },
    mounted() {
        this.jobId = this.$route.params.job

        this.getCompanySettings().then((companySettings) => {
            this.apiKey = companySettings.apiKey

            const clientId = companySettings.youstyClientID
            const clientSecret = companySettings.youstySecret

            if (clientId && clientSecret) {
                this.auth = { clientId, clientSecret }

                this.getApprenticeships(this.auth).then(() => {
                    this.getItemById(this.jobId).then(() => {
                        this.$refs.observer.validate()
                    })
                })
            }
        })
    },
    methods: {
        ...mapActions('yousty', [
            'getItemById',
            'setItem',
            'getApprenticeships',
            'publishApprenticeship',
            'unpublishApprenticeship',
        ]),
        ...mapActions('settings', ['getCompanySettings']),

        link() {
            let data = {
                clientId: this.auth.clientId,
                clientSecret: this.auth.clientSecret,
                apiKey: this.apiKey,
                jobId: this.jobId,
            }
            this.publishApprenticeship(data).then((response) => {
                if (response === 'OK') {
                    this.setItem().then(() => {})
                }
            })
        },
        unlink() {
            let data = {
                clientId: this.auth.clientId,
                clientSecret: this.auth.clientSecret,
                apiKey: this.apiKey,
                jobId: this.jobId,
            }
            this.unpublishApprenticeship(data).then((response) => {
                if (response === 'OK') {
                    this.setItem().then(() => {})
                }
            })
        },
    },
}
</script>

<style scoped></style>
