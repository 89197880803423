<template>
    <v-dialog :value="dialog" max-width="700" @click:outside="close()" @keydown.esc="close()">
        <v-card>
            <v-card-title>
                <span class="headline">{{ $t('oamjobad/label--template-selector') }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text :disabled="selectedItem.length == 0" @click="save()">
                    {{ $t('oamjobad/action--select') }}
                </v-btn>
            </v-card-actions>

            <v-card-text>
                <v-data-table
                    v-model="selectedItem"
                    :headers="headers"
                    :items="templates"
                    :item-key="templates.id"
                    :items-per-page="10"
                    :loading-text="$t('oamcommon/component--loading')"
                    show-select
                    single-select
                    class="row-pointer"
                    @click:row="rowClick"
                >
                    <template #[`item.actions`]="{ item }">
                        <v-layout v-if="item.id > 0">
                            <v-spacer />
                            <v-tooltip v-if="item.previewLink" bottom>
                                <template #activator="{ on }">
                                    <v-icon dense class="mr-3" @click="openLink(item.previewLink)" v-on="on">
                                        mdi-eye
                                    </v-icon>
                                </template>
                                {{ $t('oamcommon/tooltip--preview') }}
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon dense @click="del(item.id)" v-on="on"> mdi-delete </v-icon>
                                </template>
                                {{ $t('oamcommon/tooltip--delete') }}
                            </v-tooltip>
                        </v-layout>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close()">
                    {{ $t('oamjobad/action--close') }}
                </v-btn>
                <v-btn color="primary" text :disabled="selectedItem.length == 0" @click="save()">
                    {{ $t('oamjobad/action--select') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import { router } from '@/router'
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        ad: {
            type: Number,
            required: true,
        },
        firstCall: Boolean,
        dialog: Boolean,
    },
    data: () => ({
        selectedItem: [],
        loading: true,
    }),
    computed: {
        ...mapGetters('template', ['templates']),

        headers() {
            return [
                { text: this.$t('oamjobad/header--title'), value: 'title' },
                { text: this.$t('oamjobad/header--language'), value: 'language' },
                { text: '', value: 'actions', sortable: false, width: 120 },
            ]
        },
    },
    mounted() {
        this.getItems(this.ad).then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions('template', ['getItems', 'deleteItem']),
        ...mapActions('doc', ['createDocFromTemplate']),

        rowClick: function (item, row) {
            row.select(true)
        },

        close() {
            this.$emit('show-dialog')
        },

        save() {
            let ad = this.ad
            let id = this.selectedItem[0].id

            if (!this.firstCall) {
                this.$confirm(Vue.i18n.translate('oamcommon/message--overwrite'), {
                    title: Vue.i18n.translate('oamcommon/label--warning'),
                    buttonTrueText: Vue.i18n.translate('oamcommon/label--yes'),
                    buttonFalseText: Vue.i18n.translate('oamcommon/label--no'),
                    persistent: true,
                }).then((res) => {
                    if (res) {
                        this.createDocFromTemplate({ ad, id }).then((id) => {
                            this.$emit('show-dialog')
                            router.push({ name: 'editor', params: { id: id } })
                        })
                    }
                })
            } else {
                this.createDocFromTemplate({ ad, id }).then((id) => {
                    this.$emit('show-dialog')
                    router.push({ name: 'editor', params: { id: id } })
                })
            }
        },

        del(id) {
            this.$confirm(Vue.i18n.translate('oamcommon/message--delete'), {
                title: Vue.i18n.translate('oamcommon/label--warning'),
                buttonTrueText: Vue.i18n.translate('oamcommon/label--yes'),
                buttonFalseText: Vue.i18n.translate('oamcommon/label--no'),
                persistent: true,
            }).then((res) => {
                if (res) this.deleteItem(id)
            })
        },
        openLink(link) {
            if (link.indexOf('//') > 0) {
                window.open(link)
            } else {
                window.open('//' + link)
            }
        },
    },
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>
