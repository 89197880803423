<template>
    <v-row>
        <v-card-text>
            <p class="body-1">{{ $t('oampublication/label--jobup--category-description') }}</p>
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('oampublication/label--jobup-category-1')"
                rules="required"
            >
                <v-select
                    v-model="jobUpSubCategoryID1"
                    :items="cboValues"
                    clearable
                    item-text="text"
                    item-value="id"
                    item-disabled="disable"
                    :label="$t('oampublication/label--jobup-category-1')"
                    :error-messages="errors"
                    required
                ></v-select>
            </ValidationProvider>
            <v-select
                v-model="jobUpSubCategoryID2"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                item-disabled="disable"
                :label="$t('oampublication/label--jobup-category-2')"
            ></v-select>
            <v-select
                v-model="jobUpSubCategoryID3"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                item-disabled="disable"
                :label="$t('oampublication/label--jobup-category-3')"
            ></v-select>
            <v-select
                v-model="jobUpSubCategoryID4"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                item-disabled="disable"
                :label="$t('oampublication/label--jobup-category-4')"
            ></v-select
            ><v-select
                v-model="jobUpSubCategoryID5"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                item-disabled="disable"
                :label="$t('oampublication/label--jobup-category-5')"
            ></v-select
            ><v-select
                v-model="jobUpSubCategoryID6"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                item-disabled="disable"
                :label="$t('oampublication/label--jobup-category-6')"
            ></v-select>
        </v-card-text>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -15,
        loading: true,
        cboList: 'JobUpSubCategory',
    }),
    computed: {
        ...mapGetters('cbo', ['cboValues']),
        ...mapGetters('pub', ['getExtensionValueByKey']),
        jobUpSubCategoryID1: {
            get: function () {
                return this.getExtensionValueByKey('JobUpSubCategoryID1')
            },
            set: function (val) {
                this.editExtension({ key: 'JobUpSubCategoryID1', value: val })
            },
        },
        jobUpSubCategoryID2: {
            get: function () {
                return this.getExtensionValueByKey('JobUpSubCategoryID2')
            },
            set: function (val) {
                this.editExtension({ key: 'JobUpSubCategoryID2', value: val })
            },
        },
        jobUpSubCategoryID3: {
            get: function () {
                return this.getExtensionValueByKey('JobUpSubCategoryID3')
            },
            set: function (val) {
                this.editExtension({ key: 'JobUpSubCategoryID3', value: val })
            },
        },
        jobUpSubCategoryID4: {
            get: function () {
                return this.getExtensionValueByKey('JobUpSubCategoryID4')
            },
            set: function (val) {
                this.editExtension({ key: 'JobUpSubCategoryID4', value: val })
            },
        },
        jobUpSubCategoryID5: {
            get: function () {
                return this.getExtensionValueByKey('JobUpSubCategoryID5')
            },
            set: function (val) {
                this.editExtension({ key: 'JobUpSubCategoryID5', value: val })
            },
        },
        jobUpSubCategoryID6: {
            get: function () {
                return this.getExtensionValueByKey('JobUpSubCategoryID6')
            },
            set: function (val) {
                this.editExtension({ key: 'JobUpSubCategoryID6', value: val })
            },
        },
    },
    mounted() {
        this.getItems(this.cboList).then(() => {
            this.loading = false
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('cbo', ['getItems']),
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
