import { youstyService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const SET_LIST = 'SET_LIST'
const SET_ITEM = 'SET_ITEM'

const state = {
    apprenticeships: [],
    currentItem: {},
}

const getters = {
    apprenticeships: (state) => state.apprenticeships,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    [SET_LIST](state, items) {
        state.apprenticeships = items
    },
    [SET_ITEM](state, item) {
        state.currentItem = item
    },

    updateField,
}

const actions = {
    async getItemById({ commit }, id) {
        await youstyService.getItem(id).then((item) => {
            commit(SET_ITEM, item)
        })
    },
    // eslint-disable-next-line no-unused-vars
    async setItem({ commit }) {
        await youstyService.setItem(state.currentItem).then(() => {
            youstyService.getItem(state.currentItem.id).then((item) => {
                commit(SET_ITEM, item)
            })
        })
    },

    async getApprenticeships({ commit }, { clientId, clientSecret }) {
        await youstyService.getApprenticeships(clientId, clientSecret).then((items) => {
            commit(SET_LIST, items)
        })
    },

    // eslint-disable-next-line no-unused-vars
    async publishApprenticeship({ commit, state }, { clientId, clientSecret, apiKey, jobId }) {
        return await youstyService.publishApprenticeship(
            clientId,
            clientSecret,
            apiKey,
            state.currentItem.apprenticeshipUUID,
            jobId,
            state.currentItem.year,
            state.currentItem.trial
        )
    },
    // eslint-disable-next-line no-unused-vars
    async unpublishApprenticeship({ commit, state }, { clientId, clientSecret, apiKey, jobId }) {
        return await youstyService.unpublishApprenticeship(
            clientId,
            clientSecret,
            apiKey,
            state.currentItem.apprenticeshipUUID,
            jobId,
            state.currentItem.year,
            state.currentItem.trial
        )
    },
}

export const yousty = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
