import Vue from 'vue'

export const userService = {
    async loginUser(email, password, hash, otp) {
        try {
            const credentials = hash ? { hash } : otp ? { otp } : { email, password }
            return await Vue.axios.post('/auth/login', credentials).then((response) => {
                return response.data
            })
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async refreshToken(refreshToken) {
        try {
            return await Vue.axios.post('/auth/refresh', { refreshToken }).then((response) => {
                return response.data
            })
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async switchLanguage(language) {
        try {
            return await Vue.axios.put('/account/language', { language }).then(() => {
                Promise.resolve()
            })
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async logoutUser(double, accountId) {
        try {
            if (double) {
                return await Vue.axios.post('/auth/kickout', { accountId }).then((response) => response.data)
            }

            return await Vue.axios.post('/auth/logout').then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
