import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { store } from '@/store'
import { locale } from '@/helpers/locale'
import { storageService } from '@/services'

const LOG_SEQUENCE = false

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.axios.defaults.withCredentials = false

// 1 - Request interceptor for API calls
Vue.axios.interceptors.request.use(
    (request) => {
        let accessToken = store.getters['user/accessToken'] || storageService.get('accessToken')
        let userLanguage = locale.toNative(storageService.get('userLanguage') || store.getters['user/language'])

        if (accessToken) {
            request.headers['Authorization'] = 'Bearer ' + accessToken
        }
        if (userLanguage) {
            request.headers['Accept-Language'] = userLanguage
        }

        if (!request.url.includes('?')) {
            request.url += '?per-page=100'
        }

        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("REQUEST", "add-authorization-header-success", request);

        return request
    },
    (error) => {
        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("REQUEST", "add-authorization-header-failed", error);

        Promise.reject(error)
    }
)

// 2 - request interceptor for loading
const bypass = []
Vue.axios.interceptors.request.use((request) => {
    if (!bypass.filter((item) => request.url.includes(item)).length) {
        store.dispatch('behavior/setLoading', true)

        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("REQUEST", "loading-true", request);
    }

    return request
})

// ****************************************************************************

// 1 - response interceptor for errors (401 & 418)
Vue.axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401 || error.response.status === 418) {
            const originalRequest = error.config

            if (originalRequest.url.includes('/auth/refresh')) {
                // Refresh token has failed. Logout the user
                store.dispatch('user/logoutUser')

                // eslint-disable-next-line
            if (LOG_SEQUENCE) console.log("RESPONSE", "refresh-failed", error);

                return Promise.reject(error)
            }

            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true

                // Refresh the access token
                return store
                    .dispatch('user/refreshToken')
                    .then(() => {
                        // eslint-disable-next-line
                    if (LOG_SEQUENCE) console.log("RESPONSE", "refresh-success");

                        return Vue.axios(originalRequest)
                    })
                    .catch((error) => {
                        return Promise.resolve(error)
                    })
            }
        }

        return Promise.reject(error)
    }
)

// 2 - response interceptor for additional
Vue.axios.interceptors.response.use((response) => {
    const additional = response.headers['x-ostendis-status-additional']

    if (additional && (!Array.isArray(response.data) || response.data.length === 0)) {
        const data = {}

        additional.split(';').forEach((item) => (data[item.split('=')[0]] = item.split('=')[1]))

        Object.assign(response.data, data)
    }

    // eslint-disable-next-line
    if (LOG_SEQUENCE) console.log("RESPONSE", "additional", response);    

    return response
})

// 3 - response interceptor for alert
Vue.axios.interceptors.response.use(
    (response) => {
        const status = response.headers['x-ostendis-status-code']

        if (status > 0) {
            const type = response.headers['x-ostendis-status-type']
            const message = response.headers['x-ostendis-status-message']

            store.dispatch('alert/showAlert', {
                color: type,
                message: message,
            })
        }

        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("RESPONSE", "alert-success", response);   

        return response
    },
    (error) => {
        const status = error.response.headers['x-ostendis-status-code']

        if (status > 0) {
            const type = error.response.headers['x-ostendis-status-type']
            const message = error.response.headers['x-ostendis-status-message']

            store.dispatch('alert/showAlert', {
                color: type,
                message: message,
            })
        }

        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("RESPONSE", "alert-error", error);

        return Promise.reject(error)
    }
)

// 4 - response interceptor for loading
Vue.axios.interceptors.response.use((response) => {
    store.dispatch('behavior/setLoading', false)

    // eslint-disable-next-line
    if (LOG_SEQUENCE) console.log("RESPONSE", "loading-false", response);

    return response
})
