import { userService, storageService } from '@/services'
import { router } from '@/router'
import jwt from 'jsonwebtoken'

const LOGIN_USER = 'LOGIN_USER'
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
const REFRESH_TOKEN = 'REFRESH_TOKEN'
const SET_LANGUAGE = 'SET_LANGUAGE'
const LOGOUT_USER = 'LOGOUT_USER'

const state = {
    credentials: {
        accessToken: '',
        refreshToken: '',
    },
    error: {
        login: false,
        double: false,
    },
    language: 'de', // default language, do NOT set new value
}

const getters = {
    loggedIn: (state) => {
        return Boolean(state.credentials.accessToken) && Boolean(state.credentials.refreshToken)
    },
    accessToken: (state) => {
        return state.credentials.accessToken
    },
    language: (state) => {
        return state.language
    },
    user: (state) => {
        try {
            const accessToken = state.credentials.accessToken || storageService.get('accessToken')
            const jwtDecoded = jwt.decode(accessToken)

            const email = jwtDecoded && jwtDecoded.email
            const firstName = (jwtDecoded && jwtDecoded.firstName) || ''
            const lastName = (jwtDecoded && jwtDecoded.lastName) || ''
            const displayName = `${firstName} ${lastName}`.trim() || email

            const initials = (firstName.charAt(0) + lastName.charAt(0) || email.substring(0, 2)).toUpperCase()

            Object.assign(jwtDecoded || {}, { displayName })
            Object.assign(jwtDecoded || {}, { initials })
            Object.assign(jwtDecoded || {}, {
                hasCompany: jwtDecoded && Boolean(jwtDecoded.companyName),
            })
            Object.assign(jwtDecoded || {}, {
                role: 100, // TODO remove fake role
                isCompanyAdmin: false, // TODO remove fake role
            })

            return jwtDecoded
        } catch {
            return undefined
        }
    },
}

const mutations = {
    [LOGIN_USER](state, credentials) {
        state.credentials = credentials
        state.error.double = false
        state.error.login = false
    },
    [REFRESH_TOKEN](state, credentials) {
        state.credentials = credentials
    },
    [SET_LANGUAGE](state, language) {
        state.language = language
    },
    [SET_LOGIN_ERROR](state, error) {
        state.error.login = error
    },
    [LOGOUT_USER](state, double) {
        state.credentials.accessToken = ''
        state.credentials.refreshToken = ''
        state.error.double = double
    },
}

const actions = {
    async loginUser({ commit, getters, dispatch }, { email, password, hash, otp, path }) {
        await userService
            .loginUser(email, password, hash, otp)
            .then((credentials) => {
                commit(LOGIN_USER, credentials)

                storageService.set('accessToken', credentials.accessToken)
                storageService.set('refreshToken', credentials.refreshToken)

                dispatch('setLanguage', getters.user.language).then(() => {
                    router.replace({ path })
                })
            })
            .catch(() => {
                commit(SET_LOGIN_ERROR, true)
            })
    },

    reLoginUser({ commit }, { credentials, path }) {
        commit(LOGIN_USER, credentials)
        router.replace({ path })
    },

    async refreshToken({ commit, state }) {
        const token = state.credentials.refreshToken || storageService.get('refreshToken')
        if (token) {
            await userService
                .refreshToken(token)
                .then((credentials) => {
                    storageService.set('accessToken', credentials.accessToken)
                    storageService.set('refreshToken', credentials.refreshToken)
                    commit(REFRESH_TOKEN, credentials)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        }
    },

    setLanguage(commit, language) {
        storageService.set('userLanguage', language)
    },

    switchLanguage({ dispatch }, language) {
        userService.switchLanguage(language).then(() => {
            dispatch('setLanguage', language)
        })
    },

    async logoutUser({ commit, getters }, double) {
        if (double) {
            const accountId = getters.user.id
            await userService.logoutUser(double, accountId)
        }

        //Remove token and redirect in ANY cause
        storageService.remove('accessToken')
        commit(LOGOUT_USER, double)
        router.push({ name: '401' })
    },
}

export const user = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
