<template>
    <v-row>
        <v-card-text>
            <p class="body-1">{{ $t('oampublication/label--sozjobs-category-description') }}</p>
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('oampublication/label--sozjobs-category-1')"
                rules="required"
            >
                <v-select
                    v-model="sozjobsCategoryID1"
                    :items="cboValues"
                    clearable
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--sozjobs-category-1')"
                    :error-messages="errors"
                    required
                ></v-select>
            </ValidationProvider>
            <v-select
                v-model="sozjobsCategoryID2"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                :label="$t('oampublication/label--sozjobs-category-2')"
            ></v-select>
            <v-select
                v-model="sozjobsCategoryID3"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                :label="$t('oampublication/label--sozjobs-category-3')"
            ></v-select>
        </v-card-text>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -12,
        loading: true,
        cboList: 'SozjobsCategory',
    }),
    computed: {
        ...mapGetters('cbo', ['cboValues']),
        ...mapGetters('pub', ['getExtensionValueByKey']),
        sozjobsCategoryID1: {
            get: function () {
                return this.getExtensionValueByKey('SozjobsCategoryID1')
            },
            set: function (val) {
                this.editExtension({ key: 'SozjobsCategoryID1', value: val })
            },
        },
        sozjobsCategoryID2: {
            get: function () {
                return this.getExtensionValueByKey('SozjobsCategoryID2')
            },
            set: function (val) {
                this.editExtension({ key: 'SozjobsCategoryID2', value: val })
            },
        },
        sozjobsCategoryID3: {
            get: function () {
                return this.getExtensionValueByKey('SozjobsCategoryID3')
            },
            set: function (val) {
                this.editExtension({ key: 'SozjobsCategoryID3', value: val })
            },
        },
    },
    mounted() {
        this.getItems(this.cboList).then(() => {
            this.loading = false
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('cbo', ['getItems']),
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
