import Vue from 'vue'

const DOCS = process.env.VUE_APP_API_URL_DOCS

export const docService = {
    async createDocFromTemplate(ad, id) {
        try {
            return await Vue.axios
                .post(DOCS.slice(0, -1), {
                    jobAdId: ad,
                    htmlTemplateDocId: id,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getItem(id) {
        try {
            return await Vue.axios.get(DOCS + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async setItem(id, item) {
        try {
            return await Vue.axios.put(DOCS + id, item).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
