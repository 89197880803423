var languages = [
    { internal: 'de', native: 'de-DE' },
    { internal: 'ch', native: 'de-CH' },
    { internal: 'fr', native: 'fr' },
    { internal: 'en', native: 'en' },
    { internal: 'it', native: 'it' },
    { internal: 'es', native: 'es' },
]

const DEFAULT_LANGUAGE = 'de'

export const locale = {
    detect() {
        const native = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

        return this.toInternal(native)
    },
    toNative(internal) {
        return languages.find((language) => {
            return language.internal === internal.toLowerCase()
        }).native
    },
    toInternal(native) {
        const area = native.split('-')[1]
        let language = native.split('-')[0]

        if (language === 'de' && area === 'CH') {
            language = area.toLowerCase()
        }
        return languages.map((language) => language.internal).includes(language) ? language : DEFAULT_LANGUAGE
    },
}
