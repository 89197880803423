import Vue from 'vue'

const AD = process.env.VUE_APP_API_URL_AD
const ADS = process.env.VUE_APP_API_URL_ADS
const AD_CHECK = process.env.VUE_APP_API_URL_AD_CHECK

export const adService = {
    async newItem(job) {
        try {
            return await Vue.axios
                .post(AD.slice(0, -1), {
                    jobId: job,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async setItem(item) {
        try {
            return await Vue.axios.put(AD + item.id, item).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delItem(id) {
        try {
            return await Vue.axios.delete(AD + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getItem(id) {
        try {
            return await Vue.axios.get(AD + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async checkItem(id) {
        try {
            return await Vue.axios.get(AD_CHECK + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getItems(job) {
        try {
            return await Vue.axios.get(ADS + job).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
