<template>
    <v-dialog :value="dialog" max-width="900" @click:outside="close()" @keydown.esc="close()">
        <v-card>
            <v-card-title>
                <v-toolbar flat>
                    <span class="headline">{{ $t('oampublication/label--manage-places') }}</span>
                    <v-spacer />
                    <v-btn color="primary" @click="showAddDialog()">
                        {{ $t('oampublication/action--new-publication-place') }}
                    </v-btn>
                    <JobAdPubAddLocation v-if="addDialog" :dialog="addDialog" @show-dialog="showAddDialog()" />
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    v-model="selectedItem"
                    :headers="headers"
                    :items="userPlaces"
                    :item-key="userPlaces.id"
                    :items-per-page="10"
                    :loading-text="$t('oamcommon/component--loading')"
                >
                    <template #item="{ item }">
                        <tr>
                            <td class="d-none d-md-table-cell text-truncate" style="max-width: 300px">
                                {{ item.text }}
                            </td>
                            <td :ref="item.id" class="d-none d-md-table-cell text-truncate" style="max-width: 300px">
                                {{ item.hash }}
                            </td>
                            <td>
                                <v-layout>
                                    <v-spacer />
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon dense class="mr-2" @click="copyHash(item.hash, item.id)" v-on="on">
                                                mdi-clipboard-multiple
                                            </v-icon>
                                        </template>
                                        {{ $t('oamcommon/tooltip--copy-hash') }}
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon dense @click="del(item.id)" v-on="on"> mdi-delete </v-icon>
                                        </template>
                                        {{ $t('oamcommon/tooltip--delete') }}
                                    </v-tooltip>
                                </v-layout>
                            </td>
                        </tr>
                    </template>

                    <template #item[actions]="{ item }">
                        <v-layout>
                            <v-spacer />
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon dense @click="del(item.id)" v-on="on"> mdi-delete </v-icon>
                                </template>
                                {{ $t('oamcommon/tooltip--delete') }}
                            </v-tooltip>
                        </v-layout>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close()">
                    {{ $t('oamjobad/action--close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        dialog: Boolean,
    },
    data: () => ({
        selectedItem: [],
        loading: true,
        addDialog: false,
    }),
    computed: {
        ...mapGetters('place', ['userPlaces']),

        headers() {
            return [
                { text: this.$t('oamjobad/header--pub-title'), value: 'text' },
                { text: this.$t('oamjobad/header--hash'), value: 'hash' },
                { text: '', value: 'actions', sortable: false, width: 120 },
            ]
        },
    },
    mounted() {
        this.getUserItems().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions('place', ['getUserItems', 'deleteUserItem']),
        ...mapActions('alert', ['showSuccess']),

        showAddDialog() {
            this.addDialog = !this.addDialog
            this.getUserItems()
        },

        close() {
            this.$emit('show-dialog')
        },

        del(id) {
            this.$confirm(Vue.i18n.translate('oamcommon/message--delete'), {
                title: Vue.i18n.translate('oamcommon/label--warning'),
                buttonTrueText: Vue.i18n.translate('oamcommon/label--yes'),
                buttonFalseText: Vue.i18n.translate('oamcommon/label--no'),
                persistent: true,
            }).then((res) => {
                if (res) this.deleteUserItem(id)
            })
        },

        copyHash(hash, id) {
            let container = this.$refs[id]
            this.$copyText(hash, container)
            this.showSuccess(this.$t('oamcommon/message--hash-copied'))
        },
    },
}
</script>

<style scoped></style>
