import Vue from 'vue'

const PUB = process.env.VUE_APP_API_URL_PUB
const PUBS = process.env.VUE_APP_API_URL_PUBS
const PUBS_STATE = process.env.VUE_APP_API_URL_PUBS_STATE
const PUBS_STATE_CHECK = process.env.VUE_APP_API_URL_PUBS_STATE_CHECK
const PUBS_DEACTIVATE = process.env.VUE_APP_API_URL_PUBS_DEACTIVATE

export const pubService = {
    async newItem(id, platform) {
        try {
            return await Vue.axios
                .post(PUB.slice(0, -1), {
                    jobAdId: id,
                    jobAdPubPlatformId: platform,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async setItem(item) {
        try {
            return await Vue.axios.put(PUB + item.id, item).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delItem(id) {
        try {
            return await Vue.axios.delete(PUB + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getItem(id) {
        try {
            return await Vue.axios.get(PUB + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async getItems(ad) {
        try {
            return await Vue.axios.get(PUBS + ad).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async setStatus(id) {
        try {
            return await Vue.axios.put(PUBS_STATE + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async checkItem(id) {
        try {
            return await Vue.axios.put(PUBS_STATE_CHECK + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async deactivateAll(id) {
        try {
            return await Vue.axios.put(PUBS_DEACTIVATE + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
