<template>
    <v-row>
        <v-card-subtitle>
            {{ $t('oampublication/label--chmedia-publication-channel') }}
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <v-checkbox v-model="ostjobChannel" label="ostjob.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="myjobChannel" label="myjob.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="zentraljobChannel" label="zentraljob.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="westjobChannel" label="westjob.at"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="nicejobChannel" label="nicejob.de"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="jobmittellandChannel" label="jobmittelland.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="jobbernChannel" label="jobbern.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="jobbaselChannel" label="jobbasel.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="jobsnzzChannel" label="jobs.nzz.ch"></v-checkbox>
                </v-col>
                <v-col cols="4">
                    <v-checkbox v-model="minisiteChannel" label="Miniseite der Firma"></v-checkbox>
                </v-col>
            </v-row>
        </v-card-text>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data: () => ({
        connectorId: -12,
        loading: true,
    }),
    computed: {
        ...mapGetters('pub', ['getExtensionValueByKey']),
        ostjobChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaOstjobIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaOstjobIsActive', value: val })
            },
        },
        westjobChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaWestjobIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaWestjobIsActive', value: val })
            },
        },
        nicejobChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaNicejobIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaNicejobIsActive', value: val })
            },
        },
        myjobChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaMyjobIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaMyjobIsActive', value: val })
            },
        },
        zentraljobChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaZentraljobIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaZentraljobIsActive', value: val })
            },
        },
        jobmittellandChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaJobmittellandIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaJobmittellandIsActive', value: val })
            },
        },
        jobbernChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaJobbernIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaJobbernIsActive', value: val })
            },
        },
        jobbaselChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaJobbaselIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaJobbaselIsActive', value: val })
            },
        },
        jobsnzzChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaJobsnzzIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaJobsnzzIsActive', value: val })
            },
        },
        minisiteChannel: {
            get: function () {
                return this.getExtensionValueByKey('ChMediaMinisiteIsActive')
            },
            set: function (val) {
                this.editExtension({ key: 'ChMediaMinisiteIsActive', value: val })
            },
        },
    },
    mounted() {
        this.loading = false
        this.$emit('child-rendered')
    },
    methods: {
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
