import { adService, pubService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const ADD_ITEM = 'ADD_ITEM'
const SET_LIST = 'SET_LIST'
const SET_ITEM = 'SET_ITEM'
const SET_PARENT = 'SET_PARENT'
const SET_CURRENT = 'SET_CURRENT'
const SET_STATUS = 'SET_STATUS'
const EDIT_ITEM = 'EDIT_ITEM'
const DEL_ITEM = 'DEL_ITEM'
const RESET_CURRENT = 'RESET_CURRENT'
const SET_DOC = 'SET_DOC'

const state = {
    defaultItem: {
        id: -1,
        jobAdId: -1,
        isPublicationActive: false,
        language: 'DE',
        publicationApplyCount: 0,
        publicationFormCount: 0,
        publicationPlace: '',
        publicationReadCount: 0,
        publicationLink: '',
        publicationApplyLink: '',
        publishedAt: '',
        publishedFirst: null,
        publishedTo: '',
        title: '',
        typeId: 0,
        _type: 'Publication',
    },
    defaultAd: {
        id: 1,
        statusActive: false,
        statusText: '',
        language: 'DE',
        isYouSalutation: false,
        title: '',
        previewLink: '',
        publishedAt: '',
        teaserText: '',
        pdfFileName: '',
        pdfFileSize: 0,
        publisherPicName: '',
        publisherPicSize: '',
        cvDropperPicName: '',
        cvDropperPicSize: '',
        cvDropperText: '',
        externalUrl: null,
        htmlDocId: null,
        isTemplate: false,
        createdAt: '',
        createdBy: '',
        typeId: 0,
        _type: 'Jobad',
    },
    currentItem: {},
    currentIndex: -1,
    jobStatus: { active: false, tooltip: '🙃' },
    ads: [],
    parentId: 0,
}

const getters = {
    currentItem: (state) => state.currentItem,
    currentItemIndex: (state) => state.currentIndex,
    currentItemDocId: (state) => state.currentItem.htmlDocId,
    getField,
}

const mutations = {
    [ADD_ITEM](state) {
        state.ads.push(state.currentItem)
    },
    [SET_LIST](state, items) {
        state.jobStatus.tooltip = items[0].jobStatusText
        state.jobStatus.active = items[0].jobStatusActive

        // remove first line (job status) if title is null
        state.ads = items[0].title ? items : items.slice(1)
    },
    [SET_ITEM](state, item) {
        state.currentItem = item
    },
    [SET_PARENT](state, item) {
        state.parentId = item
    },
    [SET_CURRENT](state, index) {
        Object.assign(state.currentItem, state.list[index])
        state.currentIndex = index
    },
    [SET_STATUS](state, id) {
        const index = state.ads.findIndex((item) => item.id == id)
        state.ads[index].isPublicationActive = !state.ads[index].isPublicationActive
    },
    [EDIT_ITEM](state) {
        Object.assign(state.ads[state.currentIndex], state.currentItem)
    },
    [DEL_ITEM](state, id) {
        state.ads.splice(
            state.ads.findIndex((item) => item.jobAdId == id),
            1
        )
    },
    [RESET_CURRENT](state) {
        state.currentIndex = -1
        state.currentItem = state.defaultItem
    },
    [SET_DOC](state, id) {
        state.currentItem.htmlDocId = id
    },
    updateField,
}

const actions = {
    async getItemById({ commit }, id) {
        await adService.getItem(id).then((item) => {
            commit(SET_ITEM, item)
        })
    },

    async getItems({ commit }, parent) {
        if (parent != null) {
            commit(SET_PARENT, parent)
        }

        await adService.getItems(state.parentId).then((items) => {
            commit(SET_LIST, items)
        })
    },

    async editItem({ commit }, reset = true) {
        await adService.setItem(state.currentItem).then(() => {
            if (reset) commit(RESET_CURRENT)
        })
    },

    async addItem() {
        let id = await adService.newItem(state.parentId)
        return id
    },

    async checkItem(commit, id) {
        let check = await adService.checkItem(id)
        return check.JobAdHasOpenPubs
    },

    async deleteItem(commit, id) {
        await adService.delItem(id)
    },

    async switchPubStatus({ commit }, id) {
        await pubService.setStatus(id).catch(() => {
            commit(SET_STATUS, id) // Update only when failed
        })
    },

    setCurrent({ commit }, index) {
        commit(SET_CURRENT, index)
    },

    resetCurrent({ commit }) {
        commit(RESET_CURRENT)
    },
}

export const ad = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
