import axios from 'axios'
import { store } from '@/store'
import { locale } from '@/helpers/locale'
import { storageService } from '@/services'

const LOG_SEQUENCE = false
const JOBAD_FILE = process.env.VUE_APP_OFS_URL_JOBAD_FILE
const GENERIC_FILE = process.env.VUE_APP_OFS_URL_GENERIC_FILE

const instance = axios.create({
    baseURL: process.env.VUE_APP_OFS_URL,
    withCredentials: false,
})

// 1 - Request interceptor for API calls
instance.interceptors.request.use(
    (request) => {
        let accessToken = store.getters['user/accessToken'] || storageService.get('accessToken')
        let userLanguage = locale.toNative(storageService.get('userLanguage') || store.getters['user/language'])

        if (accessToken) {
            request.headers['Authorization'] = 'Bearer ' + accessToken
        }
        if (userLanguage) {
            request.headers['Accept-Language'] = userLanguage
        }

        if (!request.url.includes('?')) {
            request.url += '?per-page=100'
        }

        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("REQUEST", "add-authorization-header-success", request);

        return request
    },
    (error) => {
        // eslint-disable-next-line
        if (LOG_SEQUENCE) console.log("REQUEST", "add-authorization-header-failed", error);

        Promise.reject(error)
    }
)

export const ofsService = {
    async getJobadFile(id, guid) {
        try {
            return await instance
                .get(JOBAD_FILE + id + '/' + guid, { responseType: 'blob' })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async addJobadFile(id, guid, file, progress) {
        try {
            if (guid) {
                return this.editJobadFile(id, guid, file, progress)
            }

            let formData = new FormData()
            formData.append('file', file)

            return await instance
                .post(JOBAD_FILE + id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    progress,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async addOfsFile(file) {
        try {
            let formData = new FormData()
            formData.append('file', file)
            formData.append('isPublic', 1)

            return await instance
                .post(GENERIC_FILE, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async editJobadFile(id, guid, file, progress) {
        try {
            return await instance
                .put(JOBAD_FILE + id + '/' + guid, file, {
                    progress,
                })
                .then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delJobadFile(id, guid) {
        try {
            return await instance.delete(JOBAD_FILE + id + '/' + guid).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
