import Vue from 'vue'
import Router from 'vue-router'

import { store } from '@/store'
import { storageService } from '@/services'
import PageNotFound from '@/components/PageNotFound'
import Unauthorized from '@/components/Unauthorized'

Vue.use(Router)

export const router = new Router({
    mode: 'history', // see https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    routes: [
        {
            path: '/',
            name: 'home',
        },
        {
            path: '/hash/:hash',
            name: 'hash',
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: false,
            },
            component: () => import(/* WebpackChunkName: "hash" */ '../views/Hash.vue'),
            beforeEnter: (to, from, next) => {
                return process.env.NODE_ENV === 'production' ? next({ name: '404' }) : next()
            },
        },
        {
            path: '/otp/:otp',
            name: 'otp',
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: false,
            },
            component: () => import(/* WebpackChunkName: "otp" */ '../views/Otp.vue'),
        },
        {
            path: '/jobs',
            name: 'jobs',
            component: () => import(/* WebpackChunkName: "jobs" */ '../views/Jobs.vue'),
        },
        {
            path: '/job/:job',
            name: 'job',
            component: () => import(/* WebpackChunkName: "job" */ '../views/Job.vue'),
        },
        {
            path: '/job/:job/ads',
            name: 'ads',
            component: () => import(/* WebpackChunkName: "ads" */ '../views/job/Ads.vue'),
        },
        {
            path: '/job/:job/ad/:ad',
            name: 'ad',
            component: () => import(/* WebpackChunkName: "ad" */ '../views/job/Ad.vue'),
        },
        {
            path: '/job/:job/ad/:ad/pub/:pub',
            name: 'pub',
            component: () => import(/* WebpackChunkName: "pub" */ '../views/job/ad/Pub.vue'),
        },
        {
            path: '/job/:job/ad/:ad/editor/:id',
            name: 'editor',
            component: () => import(/* WebpackChunkName: "editor" */ '../views/Editor.vue'),
        },
        {
            path: '/job/:job/ad/:ad/editor/:id/adv',
            name: 'editor-adv',
            component: () => import(/* WebpackChunkName: "editorAdv" */ '../views/EditorAdv.vue'),
        },
        {
            path: '/error/401',
            name: '401',
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: false,
            },
            component: Unauthorized,
        },

        // otherwise
        {
            path: '/error/404',
            name: '404',
            meta: {
                public: true, // Allow access to even if not logged in
                onlyWhenLoggedOut: false,
            },
            component: PageNotFound,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return { selector: to.hash }
        }
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some((record) => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut)
    const loggedIn = store.getters['user/accessToken'] || storageService.get('accessToken')

    if (!isPublic && !loggedIn) {
        return next({ name: '404' })
    } else {
        // Do not allow user to visit login page or register page if they are logged in
        if (loggedIn && onlyWhenLoggedOut) {
            return next({ name: '404' })
        } else next()
    }

    router.onError((error) => {
        if (/ChunkLoadError:.*failed./i.test(error.message)) {
            window.location.reload()
        } else if (/Loading.*chunk.*failed./i.test(error.message)) {
            window.location.reload()
        }
    })
})
