<template>
    <v-row>
        <v-card-text>
            <p class="body-1">{{ $t('oampublication/label--swissdevjobs-pricemodel-description') }}</p>
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('oampublication/label--swissdevjobs-pricemodel')"
                rules="required"
            >
                <v-select
                    v-model="swissDevJobsPriceModel"
                    :items="cboValues"
                    clearable
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--swissdevjobs-pricemodel')"
                    :error-messages="errors"
                    required
                ></v-select>
            </ValidationProvider>
        </v-card-text>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -27,
        loading: true,
        cboList: 'SwissDevJobsPriceModel',
    }),
    computed: {
        ...mapGetters('cbo', ['cboValues']),
        ...mapGetters('pub', ['getExtensionValueByKey']),
        swissDevJobsPriceModel: {
            get: function () {
                return this.getExtensionValueByKey('SwissDevJobsPriceModel')
            },
            set: function (val) {
                this.editExtension({ key: 'SwissDevJobsPriceModel', value: val })
            },
        },
    },
    mounted() {
        this.getItems(this.cboList).then(() => {
            this.loading = false
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('cbo', ['getItems']),
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
