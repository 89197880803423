<template>
    <v-row>
        <v-card-text>
            <p class="body-1">{{ $t('oampublication/label--spitexjobs-category-description') }}</p>
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('oampublication/label--spitexjobs-category-1')"
                rules="required"
            >
                <v-select
                    v-model="spitexjobsCategoryID1"
                    :items="cboValues"
                    clearable
                    item-text="text"
                    item-value="id"
                    :label="$t('oampublication/label--spitexjobs-category-1')"
                    :error-messages="errors"
                    required
                ></v-select>
            </ValidationProvider>
            <v-select
                v-model="spitexjobsCategoryID2"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                :label="$t('oampublication/label--spitexjobs-category-2')"
            ></v-select>
            <v-select
                v-model="spitexjobsCategoryID3"
                :items="cboValues"
                clearable
                item-text="text"
                item-value="id"
                :label="$t('oampublication/label--spitexjobs-category-3')"
            ></v-select>
        </v-card-text>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -18,
        loading: true,
        cboList: 'SpitexjobsCategory',
    }),
    computed: {
        ...mapGetters('cbo', ['cboValues']),
        ...mapGetters('pub', ['getExtensionValueByKey']),
        spitexjobsCategoryID1: {
            get: function () {
                return this.getExtensionValueByKey('SpitexjobsCategoryID1')
            },
            set: function (val) {
                this.editExtension({ key: 'SpitexjobsCategoryID1', value: val })
            },
        },
        spitexjobsCategoryID2: {
            get: function () {
                return this.getExtensionValueByKey('SpitexjobsCategoryID2')
            },
            set: function (val) {
                this.editExtension({ key: 'SpitexjobsCategoryID2', value: val })
            },
        },
        spitexjobsCategoryID3: {
            get: function () {
                return this.getExtensionValueByKey('SpitexjobsCategoryID3')
            },
            set: function (val) {
                this.editExtension({ key: 'SpitexjobsCategoryID3', value: val })
            },
        },
    },
    mounted() {
        this.getItems(this.cboList).then(() => {
            this.loading = false
            this.$emit('child-rendered')
        })
    },
    methods: {
        ...mapActions('cbo', ['getItems']),
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
