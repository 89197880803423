import Vue from 'vue'

// i18n translation service
export const tlService = {
    async preloadTranslations(domain) {
        const base = 'translations'

        return await Vue.axios.get(`/${base}/${domain}?per-page=1000`).then((response) => {
            let source = null
            let target = null
            let language = null
            const translations = {}

            response.data.map((translation) => {
                language = translation.locale.toLowerCase()
                source = `${translation.domain.toLowerCase().replace(/_/g, '-')}/${translation.source}`
                target = translation.target
                translations[source] = target
            })

            Vue.i18n.add(language, translations)
        })
    },

    getSingleTranslation(locale, key) {
        const [domain, label] = key.split('/')

        const base = 'translations'

        return Vue.axios.get(`/${base}/${domain}?per-page=100`).then((response) => {
            function isTranslation(translation) {
                return translation.source === label
            }
            return response.data.find(isTranslation).target
        })
    },
}
