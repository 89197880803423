import { docService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const SET_LIST = 'SET_LIST'
const SET_ITEM = 'SET_ITEM'
const DEL_ITEM = 'DEL_ITEM'
const SET_ITEM_ID = 'SET_ITEM_ID'
const RESET_CURRENT = 'RESET_CURRENT'

const state = {
    defaultItem: {
        _type: 'htmlDoc',
        id: -1,
        components: '',
        assets: '',
        styles: '',
        css: '',
        html: '',
        previewLink: '',
    },
    currentItem: {},
    list: [],
    docId: null,
}

const getters = {
    docs: (state) => state.list,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    [SET_LIST](state, items) {
        state.list = items
    },
    [SET_ITEM](state, item) {
        state.currentItem = item
    },
    [SET_ITEM_ID](state, id) {
        state.currentItem.id = id
    },
    [DEL_ITEM](state, id) {
        state.list.splice(
            state.list.findIndex((item) => item.id === id),
            1
        )
    },
    [RESET_CURRENT](state) {
        state.currentItem = state.defaultItem
    },
    updateField,
}

const actions = {
    async setItemId({ commit }, id) {
        commit(SET_ITEM_ID, id)
    },
    async createDocFromTemplate({ commit }, { ad, id }) {
        let item = await docService.createDocFromTemplate(ad, id).then((item) => {
            commit(SET_ITEM, item)
            return item
        })

        return item.htmlDocId
    },
    async deleteItem({ commit }, id) {
        await docService.delItem(id).then(() => {
            commit(DEL_ITEM, id)
        })
    },
    async getItem({ commit }, id) {
        let item = await docService.getItem(id).then((item) => {
            return item
        })

        commit(SET_ITEM, item)

        return item
    },
    async editItem(commit, { id, item }) {
        await docService.setItem(id, item)
    },
}

export const doc = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
