<template>
    <v-container fluid>
        <slot v-if="err" name="error" :err="err" :vm="vm" :info="info">
            <h1>ERROR</h1>
            <h3>
                Something went wrong<br />
                {{ err }}<br />
                Please refresh the page
            </h3>
            <div></div>
            <div class="wrapper">
                <div class="ghost-copy">
                    <div class="one"></div>
                    <div class="two"></div>
                    <div class="three"></div>
                    <div class="four"></div>
                </div>
                <div class="ghost">
                    <div class="face">
                        <div class="eye"></div>
                        <div class="eye-right"></div>
                        <div class="mouth"></div>
                    </div>
                </div>
                <div class="shadow"></div>
            </div>
        </slot>
        <slot v-else></slot>
    </v-container>
</template>

<script>
export default {
    name: 'ErrorBoundary',
    props: {
        stopPropagation: Boolean,
    },
    data() {
        return {
            err: false,
            vm: null,
            info: null,
        }
    },
    errorCaptured(err, vm, info) {
        this.err = err
        this.vm = vm
        this.info = info
        return !this.stopPropagation
    },
}
</script>

<style lang="scss" scoped>
$primary: #3b5998;
$secondary: #656971;
$accent: #ff8200;

.wrapper {
    margin: 0 auto;
    position: relative;
    width: 250px;
    height: 250px;
}

.ghost {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: $primary;
    border: 1px solid $secondary;
    border-bottom: none;
    animation: float 2s ease-out infinite;
}

.ghost-copy {
    width: 50%;
    height: 53%;
    left: 25%;
    top: 10%;
    position: absolute;
    border-radius: 50% 50% 0 0;
    background: $primary;
    border: 1px solid $secondary;
    border-bottom: none;
    animation: float 2s ease-out infinite;
    z-index: 0;
}

.face {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 20%;
}
.eye,
.eye-right {
    position: absolute;
    background: $accent;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 40%;
}

.eye {
    left: 25%;
}
.eye-right {
    right: 25%;
}

.mouth {
    position: absolute;
    top: 50%;
    left: 45%;
    width: 10px;
    height: 10px;
    border: 3px solid;
    border-radius: 50%;
    border-color: transparent $accent $accent transparent;
    transform: rotate(45deg);
}

.one,
.two,
.three,
.four {
    position: absolute;
    background: $primary;
    top: 85%;
    width: 25%;
    height: 23%;
    border: 1px solid $secondary;
    z-index: 0;
}

.one {
    border-radius: 0 0 100% 30%;
    left: -1px;
}

.two {
    left: 23%;
    border-radius: 0 0 50% 50%;
}

.three {
    left: 50%;
    border-radius: 0 0 50% 50%;
}

.four {
    left: 74.5%;
    border-radius: 0 0 30% 100%;
}

.shadow {
    position: absolute;
    width: 30%;
    height: 7%;
    background: $secondary;
    left: 35%;
    top: 80%;
    border-radius: 50%;
    animation: scale 2s infinite;
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes float {
    50% {
        transform: translateY(15px);
    }
}

.bottom {
    margin-top: 10px;
}

h1 {
    color: $primary;
    text-align: center;
    font-size: 9em;
    margin: 0;
    text-shadow: -1px 0 $primary, 0 1px $primary, 1px 0 $primary, 0 -1px $primary;
    line-height: 120%;
}

h3 {
    font-size: 2em;

    text-align: center;
    color: $secondary;
    font-weight: 700;
}
</style>
