import Vue from 'vue'
import { Server, Model, Factory } from 'miragejs'
import faker from 'vue-faker'

Vue.use(faker)

export function makeServer({ environment = 'development' } = {}) {
    let server = new Server({
        environment,

        models: {
            ad: Model,
            pub: Model,
        },

        factories: {
            //ADS
            ad: Factory.extend({
                title(i) {
                    let items = ['Inserat 1', 'Inserat 2', 'Inserat 3', 'Inserat 4', 'Inserat 5']

                    return items[i % items.length]
                },

                language(i) {
                    let items = ['DE', 'FR', 'EN', 'CH', 'ES']

                    return items[i % items.length]
                },

                publishedAt() {
                    return Vue.faker().date.past()
                },

                typeId(i) {
                    let items = [10, 20, 30]

                    return items[i % items.length]
                },

                _type() {
                    return 'Publication'
                },

                isPublicationActive(i) {
                    let items = [true, false]

                    return items[i % items.length]
                },

                publishedFirst() {
                    return Vue.faker().date.past()
                },

                publishedTo() {
                    return Vue.faker().date.past()
                },

                publicationPlace(i) {
                    let items = ['Link', 'Form', 'Template', 'JobCloud', 'solique']

                    return items[i % items.length]
                },

                publicationApplyCount() {
                    return Vue.faker().random.number(30)
                },

                publicationFormCount() {
                    return Vue.faker().random.number(10)
                },

                publicationReadCount() {
                    return Vue.faker().random.number(10)
                },
            }),

            //PUBS
            pub: Factory.extend({
                title(i) {
                    let items = ['Pub 1', 'Pub 2', 'Pub 3', 'Pub 4', 'Pub 5']

                    return items[i % items.length]
                },

                id(i) {
                    return i
                },

                status(i) {
                    let items = [true, false]

                    return items[i % items.length]
                },

                date() {
                    return Vue.faker().date.past().toLocaleDateString()
                },

                type(i) {
                    let items = ['Link', 'Form', 'Template', 'JobCloud', 'solique']

                    return items[i % items.length]
                },

                clicks() {
                    return Vue.faker().random.number(30)
                },

                uniqueClicks() {
                    return Vue.faker().random.number(10)
                },
            }),
        },

        seeds(server) {
            server.createList('ad', 20)
            server.createList('pub', 20)
        },

        routes() {
            this.namespace = 'api'

            //AD
            this.post('/ad', (schema, request) => {
                let attrs = JSON.parse(request.requestBody)

                return schema.ads.create({ attrs })
            })
            this.get('/ad/:id', (schema, request) => {
                let id = request.params.id

                return schema.ads.find(id)
            })
            this.put('/ad/:id', (schema, request) => {
                let item = JSON.parse(request.data)

                return schema.ads.update(item.id, item)
            })
            this.post('/ad/:id', (schema, request) => {
                let item = JSON.parse(request.requestBody).data

                return schema.ads.insert(item)
            })
            this.del('/ad/:id', (schema, request) => {
                return schema.ads.remove(request.params.id)
            })
            this.options('/ad', () => {
                return false
            })

            //ADS
            this.get('/ads', (schema) => {
                return schema.ads.all()
            })
            this.get('/ads/:id', (schema) => {
                return schema.ads.all()
            })
            this.options('/ads', () => {
                return false
            })

            ///////////////////////////

            //PUB
            this.post('/pub', (schema, request) => {
                let attrs = JSON.parse(request.requestBody)

                return schema.pubs.create({ attrs })
            })
            this.get('/pub/:id', (schema, request) => {
                let id = request.params.id

                return schema.pubs.find(id)
            })
            this.put('/pub/:id', (schema, request) => {
                let item = JSON.parse(request.data)

                return schema.pubs.update(item.id, item)
            })
            this.post('/pub/:id', (schema, request) => {
                let item = JSON.parse(request.requestBody).data

                return schema.pubs.insert(item)
            })
            this.del('/pub/:id', (schema, request) => {
                return schema.pubs.remove(request.params.id)
            })
            this.options('/pub', () => {
                return false
            })

            //PUBS
            this.get('/pubs', (schema) => {
                return schema.pubs.all()
            })
            this.get('/pubs/:id', (schema) => {
                return schema.pubs.all()
            })
            this.options('/pubs', () => {
                return false
            })
        },
    })

    return server
}
