<template>
    <validation-observer ref="observer" v-slot="{ invalid, validated }">
        <v-dialog :value="dialog" max-width="400" @click:outside="close()" @keydown.esc="close()">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('oampublication/label--add-place') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('oamjobad/label--pub-title')"
                        rules="required|max:50"
                    >
                        <v-text-field
                            ref="placeTitle"
                            v-model="text"
                            :label="$t('oamjobad/label--pub-title')"
                            :counter="50"
                            :error-messages="errors"
                            required
                        >
                        </v-text-field>
                    </ValidationProvider>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="close()">
                        {{ $t('oamjobad/action--close') }}
                    </v-btn>
                    <v-btn color="primary" text :disabled="invalid || !validated" @click="save()">
                        {{ $t('oamjobad/action--save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        dialog: Boolean,
    },
    data: () => ({
        text: '',
        loading: true,
    }),
    computed: {},
    mounted() {
        this.$refs.observer.validate()
        setTimeout(() => {
            this.$refs['placeTitle'].focus()
        })
    },
    methods: {
        ...mapActions('place', ['addUserItem']),

        close() {
            this.$emit('show-dialog')
        },

        save() {
            this.addUserItem(this.text).then(() => {
                this.close()
            })
        },
    },
}
</script>

<style scoped></style>
