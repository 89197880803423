import { pubService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const ADD_ITEM = 'ADD_ITEM'
const SET_LIST = 'SET_LIST'
const SET_ITEM = 'SET_ITEM'
const SET_PARENT = 'SET_PARENT'
const SET_STATUS = 'SET_STATUS'
const EDIT_ITEM = 'EDIT_ITEM'
const DEL_ITEM = 'DEL_ITEM'
const RESET_CURRENT = 'RESET_CURRENT'
const TRY_PARSE = 'TRY_PARSE'
const TRY_STRINGIFY = 'TRY_STRINGIFY'
const EDIT_EXTENSION = 'EDIT_EXTENSION'

const state = {
    defaultItem: {
        _type: 'Publication',
        id: -1,
        statusActive: false,
        statusText: '',
        placeId: 0,
        createdAt: '',
        createdBy: '',
        isActive: false,
        link: '',
        applyLink: '',
        modifiedAt: '',
        modifiedBy: '',
        placeText: '',
        publishedFrom: '',
        publishedTo: '',
        remarksToPublisher: null,
        readCount: 0,
        formCount: 0,
        applyCount: 0,
        readInactiveCount: 0,
        extensions: null,
        platformId: 0,
        platformLink: '',
    },
    currentItem: {},
    currentIndex: -1,
    currentItemExtensions: [],
    pubs: [],
    parentId: 0,
}

const getters = {
    currentPub: (state) => state.currentItem,
    currentPubIndex: (state) => state.currentIndex,
    getExtensionValueByKey: (state) => (key) => {
        const index = state.currentItemExtensions.findIndex((ext) => ext.JobAdPubExtensionKey == key)
        return index > -1 ? state.currentItemExtensions[index].JobAdPubExtensionValue : ''
    },
    getField,
}

const mutations = {
    [ADD_ITEM](state) {
        state.pubs.push(state.currentItem)
    },
    [SET_LIST](state, items) {
        state.pubs = items
    },
    [SET_ITEM](state, item) {
        state.currentItem = item
    },
    [SET_PARENT](state, item) {
        state.parentId = item
    },
    [SET_STATUS](state, id) {
        const index = state.pubs.findIndex((item) => item.id == id)
        if (index > -1) {
            state.pubs[index].isActive = !state.pubs[index].isActive
        } else {
            state.currentItem.isActive = !state.currentItem.isActive
        }
    },
    [EDIT_ITEM](state) {
        Object.assign(state.pubs[state.currentIndex], state.currentItem)
    },
    [TRY_PARSE](state) {
        state.currentItemExtensions = state.currentItem.extensions ? JSON.parse(state.currentItem.extensions) : []
    },
    [TRY_STRINGIFY](state) {
        if (state.currentItemExtensions != null) {
            state.currentItem.extensions = JSON.stringify(state.currentItemExtensions)
        }
    },
    [EDIT_EXTENSION](state, item) {
        const index = state.currentItemExtensions.findIndex((ext) => ext.JobAdPubExtensionKey == item.key)
        if (index > -1) {
            // Extension found
            if (item.value) {
                // Set value
                state.currentItemExtensions[index].JobAdPubExtensionValue = item.value
            } else {
                // Remove empty extension
                state.currentItemExtensions.splice(index, 1)
            }
        } else {
            // Extension not found
            if (item.value) {
                // Add new item
                state.currentItemExtensions.push({ JobAdPubExtensionKey: item.key, JobAdPubExtensionValue: item.value })
            }
        }
    },
    [DEL_ITEM](state, id) {
        state.pubs.splice(
            state.pubs.findIndex((item) => item.id == id),
            1
        )
    },
    [RESET_CURRENT](state) {
        state.currentIndex = -1
        state.currentItem = state.defaultItem
        state.currentItemExtensions = []
    },
    updateField,
}

const actions = {
    async getItemById({ commit }, id) {
        await pubService.getItem(id).then((item) => {
            commit(SET_ITEM, item)
        })
    },

    async getItems({ commit }, parent) {
        if (parent != null) {
            commit(SET_PARENT, parent)
        }

        await pubService.getItems(state.parentId).then((items) => {
            commit(SET_LIST, items)
        })
    },

    async editItem({ commit }) {
        commit(TRY_STRINGIFY)
        await pubService.setItem(state.currentItem).then(() => {
            commit(RESET_CURRENT)
        })
    },

    async resetItem({ commit }) {
        commit(RESET_CURRENT)
    },

    editExtension({ commit }, item) {
        commit(EDIT_EXTENSION, item)
    },

    getItemExtensions({ commit }) {
        commit(TRY_PARSE)
    },

    async addItem(commit, { id, platform }) {
        return await pubService.newItem(id, platform)
    },

    async deleteItem(commit, id) {
        await pubService.delItem(id)
    },

    async switchStatus({ commit }, id) {
        await pubService.setStatus(id).catch(() => {
            commit(SET_STATUS, id) // Update only when failed
        })
    },

    async checkItem(commit, id) {
        return await pubService.checkItem(id)
    },

    async deactivateAll(commit, id) {
        await pubService.deactivateAll(id)
    },
}

export const pub = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
