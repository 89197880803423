import { cboService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const SET_LIST = 'SET_LIST'

const state = {
    defaultItem: {
        _type: 'CboValue',
        id: -1,
        text: '',
        disable: false,
    },
    list: [],
    currentItem: {},
}

const getters = {
    cboValues: (state) => state.list,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    [SET_LIST](state, items) {
        state.list = items
    },
    updateField,
}

const actions = {
    async getItems({ commit }, list) {
        await cboService.getItems(list).then((items) => {
            items.forEach((ele) => {
                ele.disable = ele.id < 0
            })
            commit(SET_LIST, items)
        })
    },
}

export const cbo = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
