import Vue from 'vue'

const EXTENSION = process.env.VUE_APP_API_URL_CONNECTOR_EXTENSION

export const extensionService = {
    async getItems(id) {
        try {
            return await Vue.axios.get(EXTENSION + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async setItem(item) {
        try {
            return await Vue.axios.put(EXTENSION + item.id, item).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
