import Vue from 'vue'

const TEMPLATES = process.env.VUE_APP_API_URL_TEMPLATES

export const templateService = {
    async getItems(id) {
        try {
            return await Vue.axios.get(TEMPLATES + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async delItem(id) {
        try {
            return await Vue.axios.delete(TEMPLATES + id).then((response) => response.data)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}
