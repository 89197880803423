import moment from 'moment'

/**
 * Converts a date with moment
 *
 * UTC -->  2020-08-07T09:48:00.000+00:00
 */

function dateDisplay(date) {
    if (!date) return ''
    return moment(date).format('DD.MM.YYYY')
}

export default dateDisplay
