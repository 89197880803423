import { ofsService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const state = {
    defaultItem: {
        id: -1,
        title: '',
    },
    currentItem: {},
    list: [],
}

const getters = {
    files: (state) => state.list,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    updateField,
}

const actions = {
    async addJobadFile(commit, { id, guid, file, progress }) {
        let response = await ofsService.addJobadFile(id, guid, file, progress).catch((err) => {
            return err.message
        })

        if (response) return response
    },

    async getJobadFile(commit, { id, guid }) {
        return await ofsService.getJobadFile(id, guid)
    },

    async delJobadFile(commit, { id, guid }) {
        await ofsService.delJobadFile(id, guid)
    },

    async addOfsFile(commit, { file }) {
        let response = await ofsService.addOfsFile(file).catch((err) => {
            return err.message
        })

        if (response) return response
    },
}

export const ofs = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
