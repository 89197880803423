import {
    required,
    max,
    max_value,
    min,
    min_value,
    mimes,
    numeric,
    size,
    ext,
    dimensions,
    image,
} from 'vee-validate/dist/rules'
import { setInteractionMode, extend, localize } from 'vee-validate'
import { storageService } from '@/services'
import { locale } from '@/helpers/locale'
import { store } from '@/store'
import moment from 'moment'
import Vue from 'vue'

let code = locale.toInternal(storageService.get('userLanguage') || store.getters['user/language'])

if (code) {
    import(`vee-validate/dist/locale/${code}.json`)
        .then((locale) => {
            localize(code, locale)
        })
        .catch((err) => {
            throw new Error(`Vee-validate locale loading failed: ${err}.`)
        })
}

setInteractionMode('eager')

extend('required', required)
extend('max', max)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)
extend('mimes', mimes)
extend('numeric', numeric)
extend('size', size)
extend('ext', ext)
extend('dimensions', dimensions)
extend('image', image)

extend('lt', {
    params: ['target'],
    validate(value, { target }) {
        if (target === '') target = '31.12.2099'
        value = moment(value, 'DD.MM.YYYY')
        target = moment(target, 'DD.MM.YYYY')
        return value.isSameOrBefore(target)
    },
    message: () => Vue.i18n.translate('oampublication/message--wrong-publishing-dates'),
})

extend('gt', {
    params: ['target'],
    validate(value, { target }) {
        if (target === '') target = '01.01.1970'
        value = moment(value, 'DD.MM.YYYY')
        target = moment(target, 'DD.MM.YYYY')
        return value.isSameOrAfter(target)
    },
    message: () => Vue.i18n.translate('oampublication/message--wrong-publishing-dates'),
})
