import { fileService } from '@/services'
import { getField, updateField } from 'vuex-map-fields'

const state = {
    defaultItem: {
        id: -1,
        title: '',
    },
    currentItem: {},
    list: [],
}

const getters = {
    files: (state) => state.list,
    currentItem: (state) => state.currentItem,
    getField,
}

const mutations = {
    updateField,
}

const actions = {
    async addFile(commit, { id, file, progress }) {
        let response = await fileService.newFile(id, file, progress).catch((err) => {
            return err.message
        })

        if (response) return response
    },

    async getFile(commit, id) {
        let file = await fileService.getFile(id)
        return file
    },

    async delFile(commit, id) {
        await fileService.delFile(id)
    },

    async addCompanyAsset(commit, { guid, type }) {
        let response = await fileService.addCompanyAsset(guid, type).catch((err) => {
            return err.message
        })

        if (response) return response
    },

    async delCompanyAsset(commit, guid) {
        await fileService.delCompanyAsset(guid)
    },
}

export const file = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
