<template>
    <v-tooltip bottom>
        <template #activator="{ on }">
            <v-icon x-small class="mr-2" :color="getColor()" v-on="on"> mdi-square </v-icon>
        </template>
        {{ tooltip }}
    </v-tooltip>
</template>

<script>
export default {
    props: {
        value: Boolean,
        tooltip: {
            type: String,
            default: '',
        },
    },
    methods: {
        getColor() {
            return this.value ? 'success' : 'error'
        },
    },
}
</script>

<style scoped>
i {
    top: -2px;
}
</style>
