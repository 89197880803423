<template>
    <v-row>
        <v-card-text>
            <p class="body-1">{{ $t('oampublication/label--jobiq-adbudget-description') }}</p>
            <ValidationProvider
                v-slot="{ errors }"
                :name="$t('oampublication/label--jobiq-adbudget')"
                rules="required|numeric|min_value:950|max_value:2000"
            >
                <v-text-field
                    v-model="jobiqAdBudget"
                    type="number"
                    prefix="CHF"
                    :label="$t('oampublication/label--jobiq-adbudget')"
                    :hint="$t('oampublication/label--jobiq-adbudget-description')"
                    :error-messages="errors"
                ></v-text-field>
            </ValidationProvider>
            <v-text-field
                v-model="jobiqAdDuration"
                type="number"
                :prefix="$t('oampublication/label--jobiq-duration')"
                :label="$t('oampublication/label--jobiq-adduration')"
                :hint="$t('oampublication/label--jobiq-adduration-description')"
                disabled
            ></v-text-field>
        </v-card-text>
    </v-row>
</template>

<script>
import { extend } from 'vee-validate'
import { min_value } from 'vee-validate/dist/rules'
import { max_value } from 'vee-validate/dist/rules'
import { ValidationProvider } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'

extend('min_value', {
    ...min_value,
    message: () => {
        return Vue.i18n.translate('oampublication/message--jobiq-adbudget-min-value')
    },
})

extend('max_value', {
    ...max_value,
    message: () => {
        return Vue.i18n.translate('oampublication/message--jobiq-adbudget-max-value')
    },
})

export default {
    components: {
        ValidationProvider,
    },
    data: () => ({
        connectorId: -35,
        loading: true,
    }),
    computed: {
        ...mapGetters('pub', ['getExtensionValueByKey']),
        jobiqAdBudget: {
            get: function () {
                return this.getExtensionValueByKey('JobiqAdBudget')
            },
            set: function (val) {
                this.editExtension({ key: 'JobiqAdBudget', value: val })
            },
        },
        jobiqAdDuration: {
            get: function () {
                return this.getExtensionValueByKey('JobiqAdDuration')
            },
            set: function (val) {
                this.editExtension({ key: 'JobiqAdDuration', value: val })
            },
        },
    },
    mounted() {
        if (!this.jobiqAdBudget) {
            this.jobiqAdBudget = 950
        }

        if (!this.jobiqAdDuration) {
            this.jobiqAdDuration = 25
        }

        this.loading = false
        this.$emit('child-rendered')
    },
    methods: {
        ...mapActions('pub', ['editExtension']),
    },
}
</script>

<style scoped></style>
